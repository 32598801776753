<template>
  <div class="dev-download">
    <div class="dev-download-left">
      <div class="name">{{ download.name }}</div>
      <div class="version">Versión {{ download.version }}</div>
    </div>
    <div class="dev-download-right">
      <DownloadButton :download="download" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["download"],
  components: {
    DownloadButton: () => import("./DownloadButton.vue"),
  },
};
</script>

<style lang="scss" scoped>
.dev-download {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin: 0 0 $mpadding 0;
    padding: 0 0 $mpadding 0;
    border-bottom: solid 1px $alto;
  }
  &:hover {
    .name {
      color: $primary_color;
    }
  }
  .name {
    font-weight: bold;
    font-size: 120%;
  }
}
</style>
